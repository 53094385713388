<template>
  <div class="artist-playlist-v2 mt-2">
    <el-row v-if="!public" id="playlists" :gutter="12">
      <el-col
        :xl="6"
        :lg="6"
        :md="12"
        :sm="12"
        :xs="24"
        class="playlist-card-col margin-bottom-12"
      >
        <kpi-card-v2
          :title="
            checkIsValueAvailableOrNot(
              'object',
              cmStatistics,
              'sp_playlist_total_reach'
            )
          "
          :text="'Total Reach'"
        />
      </el-col>
      <el-col
        :xl="6"
        :lg="6"
        :md="12"
        :sm="12"
        :xs="24"
        class="playlist-card-col margin-bottom-12"
      >
        <kpi-card-v2
          :title="
            checkIsValueAvailableOrNot(
              'object',
              cmStatistics,
              'num_sp_playlists'
            )
          "
          :text="'Total Count'"
        />
      </el-col>
      <el-col
        :xl="6"
        :lg="6"
        :md="12"
        :sm="12"
        :xs="24"
        class="playlist-card-col margin-bottom-12"
      >
        <kpi-card-v2
          :title="
            checkIsValueAvailableOrNot(
              'object',
              cmStatistics,
              'sp_editorial_playlist_total_reach'
            )
          "
          :text="'Editorial Reach'"
        />
      </el-col>
      <el-col
        :xl="6"
        :lg="6"
        :md="12"
        :sm="12"
        :xs="24"
        class="playlist-card-col margin-bottom-12"
      >
        <kpi-card-v2
          :title="
            checkIsValueAvailableOrNot(
              'object',
              cmStatistics,
              'num_sp_editorial_playlists'
            )
          "
          :text="'Editorial Count'"
        />
      </el-col>
      <el-col
        :xl="6"
        :lg="6"
        :md="12"
        :sm="12"
        :xs="24"
        class="playlist-card-col margin-bottom-12"
      >
        <kpi-card-v2
          :title="
            checkIsValueAvailableOrNot(
              'object',
              playlistsSubscriberGrowthEditorial,
              getYearKey(1)
            )
          "
          :text="getYearKey(1)"
          :sub-text="'Subscribers Growth'"
        />
      </el-col>
      <el-col
        :xl="6"
        :lg="6"
        :md="12"
        :sm="12"
        :xs="24"
        class="playlist-card-col margin-bottom-12"
      >
        <kpi-card-v2
          :title="
            checkIsValueAvailableOrNot(
              'object',
              playlistsSubscriberGrowthEditorial,
              getYearKey(2)
            )
          "
          :text="getYearKey(2)"
          :sub-text="'Subscribers Growth'"
        />
      </el-col>
      <el-col
        :xl="6"
        :lg="6"
        :md="12"
        :sm="12"
        :xs="24"
        class="playlist-card-col margin-bottom-12"
      >
        <kpi-card-v2
          :title="
            checkIsValueAvailableOrNot(
              'object',
              playlistsSubscriberGrowthEditorial,
              getYearKey(3)
            )
          "
          :text="getYearKey(3)"
          :sub-text="'Subscribers Growth'"
        />
      </el-col>
      <el-col
        :xl="6"
        :lg="6"
        :md="12"
        :sm="12"
        :xs="24"
        class="playlist-card-col margin-bottom-12"
      >
        <kpi-card-v2
          :title="
            checkIsValueAvailableOrNot(
              'object',
              playlistsSubscriberGrowthEditorial,
              getYearKey(4)
            )
          "
          :text="getYearKey(4)"
          :sub-text="'Subscribers Growth'"
        />
      </el-col>
      <el-col
        :xl="6"
        :lg="6"
        :md="12"
        :sm="12"
        :xs="24"
        class="playlist-card-col margin-bottom-12"
      >
        <kpi-card-v2
          :title="
            checkIsValueAvailableOrNot(
              'object',
              playlistsSubscriberGrowth,
              getYearKey(1)
            )
          "
          :text="getYearKey(1)"
          :sub-text="'Subscribers Editorial Growth'"
        />
      </el-col>
      <el-col
        :xl="6"
        :lg="6"
        :md="12"
        :sm="12"
        :xs="24"
        class="playlist-card-col margin-bottom-12"
      >
        <kpi-card-v2
          :title="
            checkIsValueAvailableOrNot(
              'object',
              playlistsSubscriberGrowth,
              getYearKey(2)
            )
          "
          :text="getYearKey(2)"
          :sub-text="'Subscribers Editorial Growth'"
        />
      </el-col>
      <el-col
        :xl="6"
        :lg="6"
        :md="12"
        :sm="12"
        :xs="24"
        class="playlist-card-col margin-bottom-12"
      >
        <kpi-card-v2
          :title="
            checkIsValueAvailableOrNot(
              'object',
              playlistsSubscriberGrowth,
              getYearKey(3)
            )
          "
          :text="getYearKey(3)"
          :sub-text="'Subscribers Editorial Growth'"
        />
      </el-col>
      <el-col
        :xl="6"
        :lg="6"
        :md="12"
        :sm="12"
        :xs="24"
        class="playlist-card-col margin-bottom-12"
      >
        <kpi-card-v2
          :title="
            checkIsValueAvailableOrNot(
              'object',
              playlistsSubscriberGrowth,
              getYearKey(4)
            )
          "
          :text="getYearKey(4)"
          :sub-text="'Subscribers Editorial Growth'"
        />
      </el-col>
    </el-row>
    <div class="row">
      <div class="col-12">
        <ArtistPlaylistTableV2
          v-if="!public && currentPlaylists.length > 0 && !loading"
          :data="currentPlaylists"
          :title="'Current Playlists'"
        />
        <ArtistPlaylistTableV2
          v-if="!public && pastPlaylists.length > 0 && !loading"
          :data="pastPlaylists"
          :title="'Past Playlists'"
        />

        <EmptyCardLoading
          :loading="loadingCharts"
          :type="'skeleton'"
          v-if="loadingCharts"
          :card-height="'450px'"
          :rows="10"
        />
        <div class="margin-bottom-12">
          <LineAndAreaChartV2
            v-if="
              totalPlaylistSeries.length > 0 &&
              playlistCategories.length > 0 &&
              !loadingCharts
            "
            :title="'Total Playlists'"
            :fontStyle="true"
            :chart-series="totalPlaylistSeries"
            :categories="playlistCategories"
            id="total_playlists"
            :margin="false"
          />
        </div>
        <div class="margin-bottom-12">
          <LineAndBarChartV2
            v-if="!public &&
              newPlaylistSeries.length > 0 &&
              playlistCategories.length > 0 &&
              !loadingCharts
            "
            :title="'New Playlists'"
            :fontStyle="true"
            :chart-series="newPlaylistSeries"
            :categories="playlistCategories"
            id="new_playlists"
            :margin="false"
          />
        </div>
        <div class="margin-bottom-12">
          <LineAndAreaChartV2
            v-if="
              totalSubscriberSeries.length > 0 &&
              playlistCategories.length > 0 &&
              !loadingCharts
            "
            :title="'Total Playlist Subscribers'"
            :fontStyle="true"
            :chart-series="totalSubscriberSeries"
            :categories="playlistCategories"
            id="playlist_subscribers"
            :margin="false"
          />
        </div>
        <LineAndBarChartV2
          v-if="!public &&
            newSubscribersSeries.length > 0 &&
            playlistCategories.length > 0 &&
            !loadingCharts
          "
          :title="'New Playlist Subscribers'"
          :fontStyle="true"
          :chart-series="newSubscribersSeries"
          :categories="playlistCategories"
          id="new_playlist_subscribers"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, ref } from "vue";
import { getArtistPlaylistBySpotifyID } from "@/api/mongoDB";
import LineAndAreaChartV2 from "@/artists/components/LineAndAreaChartV2.vue";
import LineAndBarChartV2 from "@/artists/components/LineAndBarChartV2.vue";
import KpiCardV2 from "@/common/components/KpiCardV2.vue";
import EmptyCardLoading from "@/common/components/EmptyCardLoading.vue";
import moment from "moment";
import {
  checkIsValueAvailableOrNot,
  tableCellClassName,
  tableRowClassName,
} from "@/utils/staticHelper";
import { useRoute } from "vue-router";
import { fetchArtistsPlaylistsDataWithSpotifyListenersV2FromMongo } from "@/api/mongoDB";
import ArtistPlaylistTableV2 from "@/artists/components/ArtistPlaylistTableV2.vue";

export default {
  name: "ArtistPlaylistsV2",
  methods: {
    checkIsValueAvailableOrNot,
    tableCellClassName,
    tableRowClassName,
  },
  components: {
    LineAndAreaChartV2,
    LineAndBarChartV2,
    KpiCardV2,
    EmptyCardLoading,
    ArtistPlaylistTableV2,
  },
  props: {
    cmStatistics: {
      type: Object,
      required: true,
    },
    playlistsSubscriberGrowth: {
      type: Object,
      required: true,
    },
    playlistsSubscriberGrowthEditorial: {
      type: Object,
      required: true,
    },
    public: {
      type: Boolean,
      required: false,
      default:false,
    },
  },
  setup() {
    const selectedPlatform = ref("spotify");
    const loading = ref(false);
    const loadingCharts = ref(false);
    const playlistChartData = ref([]);
    const newPlaylistSeries = ref([]);
    const totalPlaylistSeries = ref([]);
    const newSubscribersSeries = ref([]);
    const totalSubscriberSeries = ref([]);
    const playlistCategories = ref([]);
    const currentPlaylists = ref([]);
    const pastPlaylists = ref([]);
    const route = useRoute();
    onMounted(() => {
      loading.value = true;
      loadingCharts.value = true;
      fetchPlaylistData().finally(() => (loading.value = false));
      fetchPlaylistOfArtists().finally(() => (loadingCharts.value = false));
    });

    const formatDate = (date) => {
      return moment(date).format("MMM YY");
    };
    const fetchPlaylistData = async () => {
      loadingCharts.value = true;
      try {
        let { data } = await getArtistPlaylistBySpotifyID(
          route.params.spotifyID,
          "current"
        );
        currentPlaylists.value = data;
        data = [];
      } catch (e) {
        console.log(e);
      }
      try {
        let { data } = await getArtistPlaylistBySpotifyID(
          route.params.spotifyID,
          "past"
        );
        pastPlaylists.value = data;
        data = [];
      } catch (e) {
        console.log(e);
      }
    };
    const fetchPlaylistOfArtists = async () => {
      try {
        const { data } =
          await fetchArtistsPlaylistsDataWithSpotifyListenersV2FromMongo(
            route.params.spotifyID
          );
        const newPlaylists = [];
        const totalPlaylists = [];
        const newSubscribers = [];
        const totalSubscribers = [];
        const listeners = [];
        let lastPlaylistIncrease = 0;
        let lastSubscriberIncrease = 0;
        let lastSpotifyListeners = 0;
        for (const playlist of data) {
          newPlaylists.push(
            playlist.playlistChart ? playlist.playlistChart : 0
          );

          lastPlaylistIncrease = playlist.playlistIncrease
            ? playlist.playlistIncrease
            : lastPlaylistIncrease;

          totalPlaylists.push(lastPlaylistIncrease);

          newSubscribers.push(
            playlist.subscriberChart ? playlist.subscriberChart : 0
          );

          lastSubscriberIncrease = playlist.subscriberIncrease
            ? playlist.subscriberIncrease
            : lastSubscriberIncrease;

          totalSubscribers.push(lastSubscriberIncrease);

          lastSpotifyListeners = playlist.value
            ? playlist.value
            : lastSpotifyListeners;

          listeners.push(lastSpotifyListeners);

          playlistCategories.value.push(moment(playlist.date).format("MMM YY"));
        }
        newPlaylistSeries.value.push(
          { data: newPlaylists, name: "New Playlists", type: "column" },
          { data: listeners, name: "Spotify Listeners", type: "line" }
        );
        totalPlaylistSeries.value.push(
          { data: totalPlaylists, name: "Total Playlists", type: "area" },
          { data: listeners, name: "Spotify Listeners", type: "line" }
        );
        newSubscribersSeries.value.push(
          {
            data: newSubscribers,
            name: "New Playlist Subscribers",
            type: "column",
          },
          { data: listeners, name: "Spotify Listeners", type: "line" }
        );
        totalSubscriberSeries.value.push(
          {
            data: totalSubscribers,
            name: "Total Playlist Subscribers",
            type: "area",
          },
          { data: listeners, name: "Spotify Listeners", type: "line" }
        );
        playlistChartData.value = data;
      } catch (e) {
        console.log(e);
      }
    };

    const getYearKey = (val) => {
      const currentYear = new Date().getFullYear();
      return currentYear - val;
    };

    return {
      loading,
      currentPlaylists,
      pastPlaylists,
      selectedPlatform,
      loadingCharts,
      playlistChartData,
      newPlaylistSeries,
      totalPlaylistSeries,
      newSubscribersSeries,
      totalSubscriberSeries,
      playlistCategories,
      formatDate,
      getYearKey,
    };
  },
};
</script>
<style lang="scss" scoped>
.playlist-card-col {
  padding-bottom: calc(var(--bs-gutter-x));
}
</style>
