<template>
  <EmptyCardLoading
    :loading="loading"
    :type="'skeleton'"
    v-if="loading"
    :card-height="'400px'"
    :rows="9"
    class="mb-5 mb-xl-10"
  />
  <div
    class="shadow-card card artist-forecasts mb-5 mb-xl-10"
    v-if="averages.length > 0 && ranges.length > 0 && !loading"
  >
    <div class="card-body pb-1">
      <p class="card-title mb-10">Spotify Followers Forecasts</p>
      <AreaLineRangeHighChart
        :averages="averages"
        :ranges="ranges"
        :yLabel="'Spotify Followers'"
      />
    </div>
  </div>
</template>
<script>
import AreaLineRangeHighChart from "@/artists/components/AreaLineRangeHighChart.vue";
import EmptyCardLoading from "@/common/components/EmptyCardLoading.vue";
import { onMounted, ref, computed } from "vue";
import { useRoute } from "vue-router";
import { getArtistSpotifyFollowersForecastsV2FromMongo } from "@/api/mongoDB";
import moment from "moment";
import { useStore } from "vuex";

export default {
  name: "ArtistForecastV2",
  components: {
    AreaLineRangeHighChart,
    EmptyCardLoading,
  },
  setup() {
    const loading = ref(false);
    const route = useRoute();
    const averages = ref([]);
    const ranges = ref([]);
    onMounted(async () => {
      try {
        loading.value = true;
        let { data } = await getArtistSpotifyFollowersForecastsV2FromMongo(
          route.params.spotifyID
        );
        for (const forecast of data) {
          const average = [];
          const range = [];

          if (forecast.value && forecast.timestp) {
            average.push(
              moment(forecast.timestp).unix() * 1000,
              forecast.value
            );
            averages.value.push(average);
          }

          if (forecast.trend_lower && forecast.trend_upper && forecast.ds) {
            range.push(
              moment(forecast.ds).unix() * 1000,
              forecast.trend_lower,
              forecast.trend_upper
            );
            ranges.value.push(range);
          }
        }
      } catch (e) {
        console.error(e);
      } finally {
        loading.value = false;
      }
    });
    return { loading, route, averages, ranges };
  },
};
</script>
<style lang="scss" scoped>
.artist-forecasts {
  .card-body {
    .card-title {
      font-size: 14px;
      font-weight: 500;
      color: #33647f;
    }
  }
}
</style>
