<template>
  <CardToolbar
    :show-toolbar-buttons="false"
    :description="description"
    :title="title"
    :fontStyle="true"
    :margin="margin"
    :padding="false"
    :padding-left="true"
    :shadow-class="true"
  >
    <template v-slot:body>
      <apexchart
        height="350"
        type="area"
        :options="options"
        :series="series"
      ></apexchart>
    </template>
  </CardToolbar>
</template>
<script>
import CardToolbar from "../../common/components/CardToolbar";
import { defineComponent, ref } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import { formatNumberIntoHumanizeForm } from "@/utils/staticHelper";

export default defineComponent({
  name: "LineAndAreaChartV2",
  components: { CardToolbar },
  props: {
    description: {
      type: String,
      default: null,
    },
    title: {
      type: String,
    },
    chartSeries: {
      type: Array,
      default: () => [],
    },
    categories: {
      type: Array,
      default: () => [],
    },
    margin: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const fontSize = ref("12px");
    const labelColor = getCSSVariableValue("--bs-gray-500");
    const borderColor = getCSSVariableValue("--bs-gray-200");
    const options = ref({
      chart: {
        height: 350,
        fontFamily: "inherit",
        type: "area",
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      plotOptions: {},
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: true,
        fontSize: fontSize.value,
        fontWeight: 400,
        labels: {
          colors: labelColor,
          useSeriesColors: false,
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 0,
          inverseColors: true,
        },
        opacity: 1,
      },
      stroke: {
        curve: "smooth",
        show: true,
        width: [3, 1],
        dashArray: [0, 8],
      },
      colors: ["#009ef7", "rgb(0, 179, 0)"],
      grid: {
        borderColor: borderColor,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      markers: {
        strokeWidth: 3,
      },
      tooltip: {
        style: {
          fontSize: fontSize.value,
        },
        y: {
          formatter: function (value) {
            return formatNumberIntoHumanizeForm(value);
          },
        },
      },
      yaxis: [
        {
          title: {
            text: props.chartSeries.length > 0 ? props.chartSeries[0].name : "",
            style: {
              color: labelColor,
              fontSize: fontSize,
              fontWeight: 400,
            },
          },
          labels: {
            style: {
              colors: labelColor,
              fontSize: fontSize.value,
            },
            formatter: (value) => {
              return formatNumberIntoHumanizeForm(value);
            },
          },
        },
        {
          title: {
            text: props.chartSeries.length > 0 ? props.chartSeries[1].name : "",
            style: {
              color: labelColor,
              fontSize: fontSize,
              fontWeight: 400,
            },
          },
          opposite: true,
          labels: {
            style: {
              colors: labelColor,
              fontSize: fontSize.value,
            },
            formatter: (value) => {
              return formatNumberIntoHumanizeForm(value);
            },
          },
        },
      ],
      xaxis: {
        categories: props.categories,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        tickAmount: 30,
        labels: {
          rotate: -45,
          rotateAlways: true,
          hideOverlappingLabels: true,
          showDuplicates: false,
          style: {
            colors: labelColor,
            fontSize: fontSize.value,
          },
        },
        crosshairs: {
          position: "front",
          stroke: {
            width: 1,
            dashArray: 3,
          },
        },
        tooltip: {
          enabled: false,
        },
      },
    });
    const series = ref(props.chartSeries);
    return {
      fontSize,
      options,
      series,
    };
  },
});
</script>
