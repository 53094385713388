<template>
  <div class="row margin-bottom-12" style="margin-left: 0; margin-right: 0">
    <EmptyCardLoading
      :loading="loading"
      :type="'skeleton'"
      v-if="loading"
      :card-height="'400px'"
      :rows="9"
      class="mb-5 mb-xl-10"
    />
    <LineAndBarChartV2
      v-if="
        !loading &&
        popularityObject.data.length > 0 &&
        spotifyListenersObject.data.length > 0
      "
      :title="'Popularity'"
      :fontStyle="true"
      :chart-series="[popularityObject, spotifyListenersObject]"
      :categories="popularityCategories"
      :margin="false"
    />
  </div>
</template>
<script>
import { onMounted, ref, computed } from "vue";
import { getFanmetricSourcesBySpotifyID } from "@/api/mongoDB";
import LineAndBarChartV2 from "@/artists/components/LineAndAreaChartV2.vue";
import EmptyCardLoading from "@/common/components/EmptyCardLoading.vue";
import * as moment from "moment";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

export default {
  name: "ArtistPopularityV2",
  components: { LineAndBarChartV2, EmptyCardLoading },
  setup() {
    const loading = ref(false);
    const popularityCategories = ref([]);
    const popularityObject = ref({
      data: [],
      name: "Popularity",
      type: "area",
    });
    const store = useStore();
    const route = useRoute();
    const spotifyListenersObject = computed(
      () => store.getters["ArtistModule/getSpotifyListenersObject"]
    );
    onMounted(async () => {
      if (route.params.spotifyID) {
        await getPopularityGraphData();
      }
    });
    const getPopularityGraphData = async () => {
      try {
        loading.value = true;
        const { data } = await getFanmetricSourcesBySpotifyID(
          route.params.spotifyID,
          "popularity",
          "spotify",
          3,
          1
        );
        const artistPopularity = [];
        for (const popularity of data) {
          artistPopularity.push(popularity.value ? popularity.value : 0);
          popularityCategories.value.push(
            moment(popularity.timestp).format("MMM YY")
          );
        }
        popularityObject.value = {
          data: artistPopularity,
          name: "Popularity",
          type: "area",
        };
      } catch (e) {
        console.log(e);
      } finally {
        loading.value = false;
      }
    };
    return {
      loading,
      popularityCategories,
      popularityObject,
      spotifyListenersObject,
    };
  },
};
</script>
