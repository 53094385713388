<template>
  <div class="row" style="margin-left: 0px; margin-right: 0px">
    <EmptyCardLoading
      :loading="loading"
      :type="'skeleton'"
      v-if="loading"
      :card-height="'400px'"
      :rows="9"
      class="mb-5 mb-xl-10"
    />
    <LineAndBarChartV2
      v-if="
        !loading &&
        spotifyFollowersObject.data.length > 0 &&
        spotifyListenersObject.data.length > 0
      "
      :title="'Spotify Followers'"
      :fontStyle="true"
      :chart-series="[spotifyFollowersObject, spotifyListenersObject]"
      :categories="spotifyFollowerCategories"
    />
    <ArtistFollowersForecastV2 />
    <!--    <LineAndBarChartV2-->
    <!--      v-if="-->
    <!--        facebookFollowerSeries.length > 0 &&-->
    <!--        facebookFollowerCategories.length > 0 &&-->
    <!--        !loading-->
    <!--      "-->
    <!--      :title="'Facebook Followers'"-->
    <!--      :fontStyle="true"-->
    <!--      :chart-series="facebookFollowerSeries"-->
    <!--      :categories="facebookFollowerCategories"-->
    <!--    />-->
    <!--    <LineAndBarChartV2-->
    <!--      v-if="-->
    <!--        instagramFollowerSeries.length > 0 &&-->
    <!--        instagramFollowerCategories.length > 0 &&-->
    <!--        !loading-->
    <!--      "-->
    <!--      :title="'Instagram Followers'"-->
    <!--      :fontStyle="true"-->
    <!--      :chart-series="instagramFollowerSeries"-->
    <!--      :categories="instagramFollowerCategories"-->
    <!--    />-->
    <!--    <LineAndBarChartV2-->
    <!--      v-if="-->
    <!--        soundCloudFollowerSeries.length > 0 &&-->
    <!--        soundCloudFollowerCategories.length > 0 &&-->
    <!--        !loading-->
    <!--      "-->
    <!--      :title="'SoundCloud Followers'"-->
    <!--      :fontStyle="true"-->
    <!--      :chart-series="soundCloudFollowerSeries"-->
    <!--      :categories="soundCloudFollowerCategories"-->
    <!--    />-->
  </div>
</template>
<script>
import { onMounted, ref, computed } from "vue";
import { getFanmetricSourcesBySpotifyID } from "@/api/mongoDB";
import * as moment from "moment";
import ArtistFollowersForecastV2 from "@/artists/components/ArtistFollowersForecastV2.vue";
import LineAndBarChartV2 from "@/artists/components/LineAndAreaChartV2.vue";
import EmptyCardLoading from "@/common/components/EmptyCardLoading.vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

export default {
  name: "ArtistFollowerV2",
  components: {
    ArtistFollowersForecastV2,
    LineAndBarChartV2,
    EmptyCardLoading,
  },
  setup() {
    const loading = ref(false);
    const store = useStore();
    const spotifyFollowerCategories = ref([]);
    const route = useRoute();
    const spotifyFollowersObject = ref({
      data: [],
      name: "Spotify Followers",
      type: "area",
    });
    const spotifyListenersObject = computed(
      () => store.getters["ArtistModule/getSpotifyListenersObject"]
    );
    // const facebookFollowerSeries = ref([]);
    // const facebookFollowerCategories = ref([]);
    // const instagramFollowerSeries = ref([]);
    // const instagramFollowerCategories = ref([]);
    // const soundCloudFollowerSeries = ref([]);
    // const soundCloudFollowerCategories = ref([]);

    onMounted(async () => {
      if (route.params.spotifyID) {
        await getFollowersGraphData();
      }
      // if (ArtistUUID.value) {
      //   loading.value = true;
      //   const promises = [];
      //   promises.push(
      //     new Promise((resolve) => {
      //       getArtistPlatformFollowersV2FromMongoWithSpotifyListeners(
      //         ArtistUUID.value,
      //         "facebook",
      //         3,
      //         "asc"
      //       )
      //         .then(({ data }) => {
      //           const followers = [];
      //           const listeners = [];
      //           for (const follower of data) {
      //             followers.push(follower.followers ? follower.followers : 0);
      //             listeners.push(follower.listeners ? follower.listeners : 0);
      //             facebookFollowerCategories.value.push(
      //               moment(follower.date).format("MMM YY")
      //             );
      //           }
      //           const followersObject = {
      //             data: followers,
      //             name: "Facebook followers",
      //             type: "area",
      //           };
      //           const listenersObject = {
      //             data: listeners,
      //             name: "Spotify Listeners",
      //             type: "line",
      //           };
      //           facebookFollowerSeries.value.push(
      //             followersObject,
      //             listenersObject
      //           );
      //           resolve("");
      //         })
      //         .catch(() => resolve(""));
      //     })
      //   );
      //   promises.push(
      //     new Promise((resolve) => {
      //       getArtistPlatformFollowersV2FromMongoWithSpotifyListeners(
      //         ArtistUUID.value,
      //         "instagram",
      //         3,
      //         "asc"
      //       )
      //         .then(({ data }) => {
      //           const followers = [];
      //           const listeners = [];
      //           for (const follower of data) {
      //             followers.push(follower.followers ? follower.followers : 0);
      //             listeners.push(follower.listeners ? follower.listeners : 0);
      //             instagramFollowerCategories.value.push(
      //               moment(follower.date).format("MMM YY")
      //             );
      //           }
      //           const followersObject = {
      //             data: followers,
      //             name: "Instagram followers",
      //             type: "area",
      //           };
      //           const listenersObject = {
      //             data: listeners,
      //             name: "Spotify Listeners",
      //             type: "line",
      //           };
      //           instagramFollowerSeries.value.push(
      //             followersObject,
      //             listenersObject
      //           );
      //           resolve("");
      //         })
      //         .catch(() => resolve(""));
      //     })
      //   );
      //   promises.push(
      //     new Promise((resolve) => {
      //       getArtistPlatformFollowersV2FromMongoWithSpotifyListeners(
      //         ArtistUUID.value,
      //         "soundcloud",
      //         3,
      //         "asc"
      //       )
      //         .then(({ data }) => {
      //           const followers = [];
      //           const listeners = [];
      //           for (const follower of data) {
      //             followers.push(follower.followers ? follower.followers : 0);
      //             listeners.push(follower.listeners ? follower.listeners : 0);
      //             soundCloudFollowerCategories.value.push(
      //               moment(follower.date).format("MMM YY")
      //             );
      //           }
      //           const followersObject = {
      //             data: followers,
      //             name: "SoundCloud followers",
      //             type: "area",
      //           };
      //           const listenersObject = {
      //             data: listeners,
      //             name: "Spotify Listeners",
      //             type: "line",
      //           };
      //           soundCloudFollowerSeries.value.push(
      //             followersObject,
      //             listenersObject
      //           );
      //           resolve("");
      //         })
      //         .catch(() => resolve(""));
      //     })
      //   );
      //   promises.push(
      //     new Promise((resolve) => {
      //       getArtistPlatformFollowersV2FromMongoWithSpotifyListeners(
      //         ArtistUUID.value,
      //         "spotify",
      //         3,
      //         "asc"
      //       )
      //         .then(({ data }) => {
      //           const followers = [];
      //           const listeners = [];
      //           for (const follower of data) {
      //             followers.push(follower.followers ? follower.followers : 0);
      //             listeners.push(follower.listeners ? follower.listeners : 0);
      //             spotifyFollowerCategories.value.push(
      //               moment(follower.date).format("MMM YY")
      //             );
      //           }
      //           const followersObject = {
      //             data: followers,
      //             name: "Spotify followers",
      //             type: "area",
      //           };
      //           const listenersObject = {
      //             data: listeners,
      //             name: "Spotify Listeners",
      //             type: "line",
      //           };
      //           spotifyFollowerSeries.value.push(
      //             followersObject,
      //             listenersObject
      //           );
      //           resolve("");
      //         })
      //         .catch(() => resolve(""));
      //     })
      //   );
      //   await Promise.all(promises);
      //   loading.value = false;
      // }
    });

    const getFollowersGraphData = async () => {
      try {
        loading.value = true;
        const { data } = await getFanmetricSourcesBySpotifyID(
          route.params.spotifyID,
          "followers",
          "spotify",
          3,
          1
        );
        const spotifyFollowers = [];
        for (const follower of data) {
          spotifyFollowers.push(follower.value ? follower.value : 0);
          spotifyFollowerCategories.value.push(
            moment(follower.timestp).format("MMM YY")
          );
        }
        spotifyFollowersObject.value = {
          data: spotifyFollowers,
          name: "Spotify Followers",
          type: "area",
        };
      } catch (e) {
        console.log(e);
      } finally {
        loading.value = false;
      }
    };

    return {
      loading,
      spotifyFollowersObject,
      spotifyFollowerCategories,
      spotifyListenersObject,
      // facebookFollowerSeries,
      // facebookFollowerCategories,
      // instagramFollowerSeries,
      // instagramFollowerCategories,
      // soundCloudFollowerSeries,
      // soundCloudFollowerCategories,
    };
  },
};
</script>
