<template>
  <div class="spotify-top-10-tracks">
    <CardToolbar
      v-if="topTenTracks.length > 0"
      title="Top Tracks on Spotify"
      :description="`Artist's top ${
        topTenTracks.length > 0 ? topTenTracks.length : ''
      } tracks`"
      :show-toolbar-buttons="public?false:true"
      :fontStyle="true"
      :padding="false"
      :shadow-class="true"
      :margin="false"
      v-loading="loading"
    >
      <template v-slot:toolbar>
        <el-checkbox
          style="margin-right: 1rem"
          :disabled="topTenTracks.length === 0"
          v-model="allSelected"
          @change="selectAllClicked"
          label="Select all on page"
          size="large"
          v-if="!public"
        ></el-checkbox>
        <button
          :disabled="topTenTracks.length === 0 || selectedTracks.length === 0"
          @click="addTracksStatsBySpotifyIDAndTrackID"
          class="
            btn btn-sm btn-color-muted btn-active btn-active-primary
            px-4
            me-1
            active
          "
          v-if="!public"
        >
          Update
        </button>
        <button
          @click="showWishListModal"
          :disabled="topTenTracks.length === 0 || selectedTracks.length === 0"
          class="
            btn btn-sm btn-color-muted btn-active btn-active-primary
            px-4
            me-1
            active
          "
          v-if="!public"
        >
          Add to Wishlist
        </button>
      </template>
      <template v-slot:body>
        <div style="overflow-y: scroll; max-height: 895px">
          <el-table
            :data="topTenTracks"
            :fit="true"
            size="medium"
            stripe
            style="width: 100%"
            header-cell-class-name="table-header-text"
            :row-class-name="tableRowClassName"
            :cell-class-name="tableCellClassName"
          >
            <el-table-column label="Select" width="80" fixed  v-if="!public">
              <template #default="scope">
                <el-checkbox
                  :model-value="selectedTracks.includes(scope.row.trackID)"
                  :disabled="
                    !selectedTracks.includes(scope.row.trackID) &&
                    selectedTracks.length > 49
                  "
                  @change="checkBoxValueChanged($event, scope.row.trackID)"
                  size="large"
                ></el-checkbox>
              </template>
            </el-table-column>
            <el-table-column label="Track" width="300" fixed>
              <template #default="scope">
                <div
                  class="d-flex align-items-center cursor-pointer"
                  @click="
                    routeToPage(
                      `song/${scope.row.spotifyID}/${scope.row.trackID}`,
                      true
                    )
                  "
                >
                  <el-avatar :size="25" :src="scope.row.imageURL"></el-avatar>
                  <span
                    class="ms-2 truncate"
                    data-tag="span"
                    title=""
                    data-tooltip="true"
                    :data-text="scope.row.track ? scope.row.track : 'N/A'"
                  >
                    {{ scope.row.track ? scope.row.track : "N/A" }}
                  </span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="Artist" width="250">
              <template #default="scope">
                <p
                  class="truncate"
                  data-tag="p"
                  title=""
                  data-tooltip="true"
                  :data-text="scope.row.artists ? scope.row.artists : 'N/A'"
                >
                  {{ scope.row.artists ? scope.row.artists : "N/A" }}
                </p>
              </template>
            </el-table-column>
            <el-table-column label="All Time Streams" width="230">
              <template #default="scope">
                <p
                  class="truncate"
                  data-tag="p"
                  title=""
                  data-tooltip="true"
                  :data-text="
                    checkIsValueAvailableOrNot(
                      'key',
                      null,
                      scope.row.spotifyStreams
                    )
                  "
                >
                  {{
                    checkIsValueAvailableOrNot(
                      "key",
                      null,
                      scope.row.spotifyStreams
                    )
                  }}
                </p>
              </template>
            </el-table-column>
            <el-table-column label="Label" width="230">
              <template #default="scope">
                <p
                  class="truncate"
                  data-tag="p"
                  title=""
                  data-tooltip="true"
                  :data-text="scope.row.label ? scope.row.label : 'N/A'"
                >
                  {{ scope.row.label ? scope.row.label : "N/A" }}
                </p>
              </template>
            </el-table-column>
            <el-table-column label="Release Date" width="130">
              <template #default="scope">
                {{
                  scope.row.releaseDate
                    ? moment(scope.row.releaseDate).format("MMM DD, YYYY")
                    : "N/A"
                }}
              </template>
            </el-table-column>
            <el-table-column label="" width="60" align="right">
              <template #default="scope">
                <span
                  :class="[
                    'svg-icon svg-icon-checkmark',
                    scope.row.indicator ? 'svg-icon-primary' : '',
                  ]"
                >
                  <inline-svg src="/media/icons/duotune/general/gen026.svg" />
                </span>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div
          @click="showMoreTracks"
          class="
            cursor-pointer
            d-flex
            justify-content-center
            mt-4
            mb-4
            p-2
            fw-bolder
            theme-v2-color
          "
          style="background: #dee4ee; border-radius: 4px"
          v-if="!public"
        >
          + Show More
        </div>
      </template>
    </CardToolbar>
  </div>
</template>
<script>
import CardToolbar from "@/common/components/CardToolbar";
import { ref } from "vue";
import moment from "moment";
import { useRoute } from "vue-router";
import {
  checkIsValueAvailableOrNot,
  getUniqueValuesFromArray,
  routeToPage,
  tableCellClassName,
  tableRowClassName,
} from "@/utils/staticHelper";
import {
  addTracksBySpotifyIdAndTrackId,
  getTop10TracksFromMongo,
} from "@/api/mongoTracks";
import { ElNotification } from "element-plus";
import { useStore } from "vuex";

export default {
  name: "SpotifyTop10TracksV2",
  methods: { tableCellClassName, tableRowClassName, routeToPage },
  components: {
    CardToolbar,
  },
  props: {
    tracks: {
      type: Array,
      default: () => [],
    },
    public: {
      type: Boolean,
      required: false,
      default:false,
    },
  },
  setup(props) {
    const loading = ref(false);
    const store = useStore();
    const topTenTracks = ref(props.tracks.length > 0 ? props.tracks : []);
    const selectedTracks = ref([]);
    const allSelected = ref(false);
    const route = useRoute();
    const limit = ref(15);
    const checkBoxValueChanged = (event, id) => {
      allSelected.value = false;
      if (event && !selectedTracks.value.includes(id)) {
        selectedTracks.value.push(id);
      } else if (!event) {
        const index = selectedTracks.value.indexOf(id);
        if (index > -1) {
          selectedTracks.value.splice(index, 1);
        }
      }
    };
    const selectAllClicked = (value) => {
      if (value) {
        selectedTracks.value = topTenTracks.value.map(
          (object) => object.trackID
        );
      } else {
        selectedTracks.value = [];
      }
    };
    const addTracksStatsBySpotifyIDAndTrackID = async () => {
      try {
        loading.value = true;
        const mappedTracks = selectedTracks.value.map((id) => {
          return {
            chartmetricTrackID: id,
          };
        });
        addTracksBySpotifyIdAndTrackId(mappedTracks, route.params.spotifyID);
        selectedTracks.value = [];
        allSelected.value = false;
        ElNotification({
          title: "Success",
          message: "Selected Tracks Stats updated!",
          type: "success",
        });
      } catch (e) {
        ElNotification({
          title: "Error",
          message: "Error updating tracks stats",
          type: "error",
        });
        console.log(e);
      } finally {
        loading.value = false;
      }
    };

    const showWishListModal = async () => {
      if (selectedTracks.value.length > 0) {
        const tracks = topTenTracks.value
          .filter((track) => selectedTracks.value.includes(track.trackID))
          .map((g) => g);
        await store.commit("WishlistModule/SET_NEW_WISHLIST_TRACKS", tracks);
        await store.commit(
          "WishlistModule/SET_SHOW_CREATE_WISHLIST_MODAL",
          true
        );
      }
      selectedTracks.value = [];
      allSelected.value = false;
    };

    const showMoreTracks = async () => {
      try {
        loading.value = true;
        limit.value += 10;
        const { data } = await getTop10TracksFromMongo(
          route.params.spotifyID,
          limit.value
        );
        topTenTracks.value = data;
        topTenTracks.value = getUniqueValuesFromArray(data, "track");
      } catch (e) {
        console.log(e);
      } finally {
        loading.value = false;
      }
    };

    return {
      loading,
      topTenTracks,
      selectedTracks,
      allSelected,
      checkIsValueAvailableOrNot,
      checkBoxValueChanged,
      selectAllClicked,
      addTracksStatsBySpotifyIDAndTrackID,
      showWishListModal,
      showMoreTracks,
      moment,
    };
  },
};
</script>
