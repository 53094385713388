<template>
  <div
    class="artist-performance-v2"
    id="artist-performance-v2"
    :class="!public ? 'mt-2' : ''"
  >
    <el-row v-if="!public" id="monthly_listeners" :gutter="12">
      <el-col class="margin-bottom-12" :lg="6" :md="12" :sm="12" :xs="24">
        <kpi-card-v2
          :title="
            checkIsValueAvailableOrNot(
              'object',
              chartmetricSpotifyListenersGrowth,
              '12Months',
              '%'
            )
          "
          :text="'12 Months'"
          :subText="'Listener Growth'"
        />
      </el-col>
      <el-col class="margin-bottom-12" :lg="6" :md="12" :sm="12" :xs="24">
        <kpi-card-v2
          :title="
            checkIsValueAvailableOrNot(
              'object',
              chartmetricSpotifyListenersGrowth,
              '24Months',
              '%'
            )
          "
          :text="'24 Months'"
          :subText="'Listener Growth'"
        />
      </el-col>
      <el-col class="margin-bottom-12" :lg="6" :md="12" :sm="12" :xs="24">
        <kpi-card-v2
          :title="
            checkIsValueAvailableOrNot(
              'object',
              chartmetricSpotifyListenersGrowth,
              '36Months',
              '%'
            )
          "
          :text="'36 Months'"
          :subText="'Listener Growth'"
        />
      </el-col>
      <el-col class="margin-bottom-12" :lg="6" :md="12" :sm="12" :xs="24">
        <kpi-card-v2
          :title="
            checkIsValueAvailableOrNot(
              'object',
              chartmetricSpotifyListenersGrowth,
              '48Months',
              '%'
            )
          "
          :text="'48 Months'"
          :subText="'Listener Growth'"
        />
      </el-col>
    </el-row>
    <el-row v-if="!public" id="yearly_listeners_growth" :gutter="12">
      <el-col class="margin-bottom-12" :lg="6" :md="12" :sm="12" :xs="24">
        <kpi-card-v2
          :title="
            checkIsValueAvailableOrNot(
              'object',
              chartmetricSpotifyListenersGrowth,
              getYearKey(1),
              '%'
            )
          "
          :text="getYearKey(1)"
          :subText="'Listener Growth'"
        />
      </el-col>
      <el-col class="margin-bottom-12" :lg="6" :md="12" :sm="12" :xs="24">
        <kpi-card-v2
          :title="
            checkIsValueAvailableOrNot(
              'object',
              chartmetricSpotifyListenersGrowth,
              getYearKey(2),
              '%'
            )
          "
          :text="getYearKey(2)"
          :subText="'Listener Growth'"
        />
      </el-col>
      <el-col class="margin-bottom-12" :lg="6" :md="12" :sm="12" :xs="24">
        <kpi-card-v2
          :title="
            checkIsValueAvailableOrNot(
              'object',
              chartmetricSpotifyListenersGrowth,
              getYearKey(3),
              '%'
            )
          "
          :text="getYearKey(3)"
          :subText="'Listener Growth'"
        />
      </el-col>
      <el-col class="margin-bottom-12" :lg="6" :md="12" :sm="12" :xs="24">
        <kpi-card-v2
          :title="
            checkIsValueAvailableOrNot(
              'object',
              chartmetricSpotifyListenersGrowth,
              getYearKey(4),
              '%'
            )
          "
          :text="getYearKey(4)"
          :subText="'Listener Growth'"
        />
      </el-col>
    </el-row>
    <ArtistListenersV2
      id="listener_trend"
      :spotify-listeners-trend-growth="spotifyListenersTrendGrowth"
      :chartmetric-spotify-listeners-total="chartmetricSpotifyListenersTotal"
      :public="public"
    />
    <ArtistListenersForecastV2 v-if="!public" />
    <ArtistPopularityV2 v-if="!public" id="popularity" />
    <el-row :gutter="12" v-if="!public" id="monthly_follower">
      <el-col class="margin-bottom-12" :lg="6" :md="12" :sm="12" :xs="24">
        <kpi-card-v2
          :title="
            checkIsValueAvailableOrNot(
              'object',
              chartmetricSpotifyFollowersGrowth,
              '12Months',
              '%'
            )
          "
          :text="'12 Months'"
          :subText="'Followers Growth'"
        />
      </el-col>
      <el-col class="margin-bottom-12" :lg="6" :md="12" :sm="12" :xs="24">
        <kpi-card-v2
          :title="
            checkIsValueAvailableOrNot(
              'object',
              chartmetricSpotifyFollowersGrowth,
              '24Months',
              '%'
            )
          "
          :text="'24 Months'"
          :subText="'Followers Growth'"
        />
      </el-col>
      <el-col class="margin-bottom-12" :lg="6" :md="12" :sm="12" :xs="24">
        <kpi-card-v2
          :title="
            checkIsValueAvailableOrNot(
              'object',
              chartmetricSpotifyFollowersGrowth,
              '36Months',
              '%'
            )
          "
          :text="'36 Months'"
          :subText="'Followers Growth'"
        />
      </el-col>
      <el-col class="margin-bottom-12" :lg="6" :md="12" :sm="12" :xs="24">
        <kpi-card-v2
          :title="
            checkIsValueAvailableOrNot(
              'object',
              chartmetricSpotifyFollowersGrowth,
              '48Months',
              '%'
            )
          "
          :text="'48 Months'"
          :subText="'Followers Growth'"
        />
      </el-col>
    </el-row>
    <el-row :gutter="12" v-if="!public" id="yearly_followers_growth">
      <el-col class="margin-bottom-12" :lg="6" :md="12" :sm="12" :xs="24">
        <kpi-card-v2
          :title="
            checkIsValueAvailableOrNot(
              'object',
              chartmetricSpotifyFollowersGrowth,
              getYearKey(1),
              '%'
            )
          "
          :text="getYearKey(1)"
          :subText="'Followers Growth'"
        />
      </el-col>
      <el-col class="margin-bottom-12" :lg="6" :md="12" :sm="12" :xs="24">
        <kpi-card-v2
          :title="
            checkIsValueAvailableOrNot(
              'object',
              chartmetricSpotifyFollowersGrowth,
              getYearKey(2),
              '%'
            )
          "
          :text="getYearKey(2)"
          :subText="'Followers Growth'"
        />
      </el-col>
      <el-col class="margin-bottom-12" :lg="6" :md="12" :sm="12" :xs="24">
        <kpi-card-v2
          :title="
            checkIsValueAvailableOrNot(
              'object',
              chartmetricSpotifyFollowersGrowth,
              getYearKey(3),
              '%'
            )
          "
          :text="getYearKey(3)"
          :subText="'Followers Growth'"
        />
      </el-col>
      <el-col class="margin-bottom-12" :lg="6" :md="12" :sm="12" :xs="24">
        <kpi-card-v2
          :title="
            checkIsValueAvailableOrNot(
              'object',
              chartmetricSpotifyFollowersGrowth,
              getYearKey(4),
              '%'
            )
          "
          :text="getYearKey(4)"
          :subText="'Followers Growth'"
        />
      </el-col>
    </el-row>
    <ArtistFollowersV2 v-if="!public" />
  </div>
</template>
<script>
import ArtistPopularityV2 from "@/artists/components/ArtistPopularityV2.vue";
import ArtistListenersV2 from "@/artists/components/ArtistListenersV2.vue";
import ArtistFollowersV2 from "@/artists/components/ArtistFollowersV2.vue";
import ArtistListenersForecastV2 from "@/artists/components/ArtistListenersForecastV2.vue";
import KpiCardV2 from "@/common/components/KpiCardV2.vue";
import { checkIsValueAvailableOrNot } from "@/utils/staticHelper";

export default {
  name: "ArtistPerformanceV2",
  props: {
    chartmetricSpotifyListenersGrowth: {
      type: Object,
      required: true,
    },
    chartmetricSpotifyFollowersGrowth: {
      type: Object,
      required: true,
    },
    spotifyListenersTrendGrowth: {
      type: Object,
      required: true,
    },
    chartmetricSpotifyListenersTotal: {
      type: Number,
      required: true,
    },
    public: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    ArtistPopularityV2,
    ArtistFollowersV2,
    ArtistListenersV2,
    ArtistListenersForecastV2,
    KpiCardV2,
  },
  setup() {
    const getYearKey = (val) => {
      const currentYear = new Date().getFullYear();
      return currentYear - val;
    };
    return { checkIsValueAvailableOrNot, getYearKey };
  },
};
</script>
