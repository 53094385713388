<template>
  <div class="artist-listeners">
    <el-row v-if="!public" :gutter="12">
      <el-col class="margin-bottom-12" :lg="12" :md="12" :sm="12" :xs="24">
        <kpi-card-v2
          :title="
            checkIsValueAvailableOrNot(
              'object',
              trendPercentage,
              'slope',
              '',
              4
            )
          "
          :text="'Monthly Listeners Slope'"
          :sub-text="'Listeners Trend'"
        />
      </el-col>
      <el-col class="margin-bottom-12" :lg="12" :md="12" :sm="12" :xs="24">
        <kpi-card-v2
          :title="
            checkIsValueAvailableOrNot(
              'object',
              trendPercentage,
              'percent',
              '%',
              2
            )
          "
          :text="'Monthly Growth Trend'"
          :sub-text="'Listeners Trend'"
        />
      </el-col>
    </el-row>
    <EmptyCardLoading
      :loading="loading"
      :type="'skeleton'"
      v-if="loading"
      :card-height="'400px'"
      :rows="9"
      class="mb-5 mb-xl-10 mt-8"
    />
    <div
      v-if="series.length > 0 && categories.length > 0 && !loading"
      class="margin-bottom-12"
    >
      <CardToolbar
        :show-toolbar-buttons="false"
        :description="null"
        :title="'Spotify Listeners Trend'"
        :fontStyle="true"
        :margin="false"
        :padding="false"
        :padding-left="false"
        :shadow-class="true"
      >
        <template v-slot:body>
          <ScoreChartV2
            :stroke="stroke"
            :chart-series="series"
            :categories="categories"
            :show-label="true"
            :show-yaxis-title="'Spotify Listeners'"
            :show-xaxis-title="''"
          />
        </template>
      </CardToolbar>
    </div>
  </div>
</template>
<script>
import createTrend from "trendline";
import { computed, onMounted, ref, watch } from "vue";
import moment from "moment";
import {
  getFanmetricSourcesBySpotifyID,
  updateArtistListenersTrend,
} from "@/api/mongoDB";
import KpiCardV2 from "@/common/components/KpiCardV2.vue";
import EmptyCardLoading from "@/common/components/EmptyCardLoading.vue";
import CardToolbar from "@/common/components/CardToolbar.vue";
import ScoreChartV2 from "@/artists/components/ScoreChartV2.vue";
import { useRoute } from "vue-router";
import { checkIsValueAvailableOrNot } from "@/utils/staticHelper";
import { useStore } from "vuex";
import { round } from "@popperjs/core/lib/utils/math";

export default {
  name: "AreaLineRangeHighChart",
  components: {
    KpiCardV2,
    EmptyCardLoading,
    CardToolbar,
    ScoreChartV2,
  },
  props: {
    spotifyListenersTrendGrowth: {
      type: Object,
      required: true,
    },
    chartmetricSpotifyListenersTotal: {
      type: Number,
      required: true,
    },
    public: {
      type: Boolean,
      required: false,
      default:false,
    },
  },
  setup(props) {
    const loading = ref(false);
    const store = useStore();
    const series = ref([]);
    const categories = ref([]);
    const stroke = ref({
      width: [3, 1],
      dashArray: [0, 8],
      curve: "smooth",
    });
    const trendPercentage = ref({
      slope: 0,
      percent: 0,
    });
    const apiResponseData = ref([]);
    const route = useRoute();
    onMounted(async () => {
      if (route.params.spotifyID) {
        await getListenersTrendGraphData();
      }
    });
    const listenersTrendStatus = computed(
      () => store.getters["ForecastModule/getUpdateListenersTrendGrowthStatus"]
    );

    const getListenersTrendGraphData = async () => {
      try {
        loading.value = true;
        let { data } = await getFanmetricSourcesBySpotifyID(
          route.params.spotifyID,
          "listeners",
          "spotify",
          3,
          1,
          true
        );
        series.value = [];
        categories.value = [];
        apiResponseData.value = data;
        const spotifyListeners = [];
        const listenersArray = [];
        for (const listener of data) {
          spotifyListeners.push(listener.value);
          categories.value.push(moment(listener.timestp).format("MMM YY"));
          listenersArray.push({
            x: moment(listener.timestp).unix(),
            y: Number(listener.value),
          });
        }
        series.value.push({
          name: "Spotify Listeners",
          type: "line",
          data: spotifyListeners,
        });
        const trend = createTrend(listenersArray, "x", "y");
        console.log(trend);
        console.log("listeners===>", props.chartmetricSpotifyListenersTotal);
        const trendline = [];
        for (const listener of apiResponseData.value) {
          trendline.push(
            Math.round(trend.calcY(moment(listener.timestp).unix()))
          );
        }

        series.value.push({
          name: "Trend Line",
          type: "line",
          data: trendline,
        });

        if (
          listenersTrendStatus.value ||
          Object.keys(props.spotifyListenersTrendGrowth).length === 0
        ) {
          trendPercentage.value.slope = trend.slope;
          const percent =
            (trend.slope / props.chartmetricSpotifyListenersTotal) * 100;
          trendPercentage.value.percent = Number(percent.toFixed(4));

          await saveSpotifyListenersTrend(trendPercentage.value);
        } else {
          trendPercentage.value.slope =
            props.spotifyListenersTrendGrowth &&
            props.spotifyListenersTrendGrowth["slope"]
              ? props.spotifyListenersTrendGrowth["slope"]
              : 0;
          trendPercentage.value.percent =
            props.spotifyListenersTrendGrowth &&
            props.spotifyListenersTrendGrowth["percent"]
              ? props.spotifyListenersTrendGrowth["percent"]
              : 0;
        }
        data = {};
      } catch (e) {
        console.error(e);
      } finally {
        loading.value = false;
      }
    };

    const saveSpotifyListenersTrend = async (payload) => {
      try {
        await updateArtistListenersTrend(route.params.spotifyID, {
          spotifyListenersTrendGrowth: payload,
        });
      } catch (e) {
        console.log(e);
      }
    };
    watch(
      () => listenersTrendStatus.value,
      (value) => {
        if (value) {
          getListenersTrendGraphData();
        }
      }
    );

    return {
      loading,
      series,
      categories,
      trendPercentage,
      stroke,
      checkIsValueAvailableOrNot,
    };
  },
};
</script>
<style lang="scss" scoped>
.artist-listeners {
  .card {
    .card-body {
      .card-title {
        font-size: 14px;
        font-weight: 500;
        color: #33647f;
      }
    }
  }
}
</style>
