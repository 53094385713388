<template>
  <CardToolbar
    v-loading="loading"
    :title="title"
    :show-toolbar-buttons="false"
    :fontStyle="true"
    :padding="false"
    :shadow-class="true"
    margin-class="margin-bottom-12"
    :margin="true"
  >
    <template v-slot:body>
      <el-table
        @sort-change="sortResults"
        v-loading="loading"
        :fit="true"
        size="medium"
        :data="tableData"
        stripe
        style="width: 100%"
        header-cell-class-name="table-header-text"
        :row-class-name="tableRowClassName"
        :cell-class-name="tableCellClassName"
      >
        <el-table-column
          fixed
          width="200"
          sortable="custom"
          prop="playlist"
          label="Playlist"
        >
          <template #default="scope">
            <p
              class="truncate"
              data-tag="p"
              title=""
              data-tooltip="true"
              :data-text="scope.row.playlist ? scope.row.playlist : ''"
            >
              {{ scope.row.playlist ? scope.row.playlist : "" }}
            </p>
          </template>
        </el-table-column>
        <el-table-column
          sortable="custom"
          prop="track"
          label="Track"
          width="180"
        >
          <template #default="scope">
            <p
              class="truncate"
              data-tag="p"
              title=""
              data-tooltip="true"
              :data-text="scope.row.track ? scope.row.track : ''"
            >
              {{ scope.row.track ? scope.row.track : "" }}
            </p>
          </template>
        </el-table-column>
        <el-table-column
          sortable="custom"
          prop="followers"
          label="Followers"
          width="120"
          align="right"
        >
          <template #default="scope">
            {{ scope.row.followers ? scope.row.followers : "" }}
          </template>
        </el-table-column>
        <el-table-column
          sortable="custom"
          prop="followersDiffMonth"
          label="Followers Diff Month"
          width="170"
          align="right"
        >
          <template #default="scope">
            {{
              scope.row.followersDiffMonth ? scope.row.followersDiffMonth : ""
            }}
          </template>
        </el-table-column>
        <el-table-column
          sortable="custom"
          prop="addedAt"
          label="Added At"
          width="120"
          align="right"
        >
          <template #default="scope">
            {{ scope.row.addedAt ? scope.row.addedAt : "" }}
          </template>
        </el-table-column>
        <el-table-column
          sortable="custom"
          prop="position"
          label="Position"
          width="120"
          align="right"
        >
          <template #default="scope">
            {{ scope.row.position ? scope.row.position : "" }}
          </template>
        </el-table-column>
        <el-table-column
          sortable="custom"
          prop="peakPosition"
          label="Peak Position"
          width="140"
          align="right"
        >
          <template #default="scope">
            {{ scope.row.peakPosition ? scope.row.peakPosition : "" }}
          </template>
        </el-table-column>
        <el-table-column
          sortable="custom"
          prop="daysOnPeriod"
          label="Days On Playlist"
          width="150"
          align="right"
        >
          <template #default="scope">
            {{ scope.row.daysOnPeriod ? scope.row.daysOnPeriod : "" }}
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="mt-7 mb-7"
        v-model:currentPage="localPagination.offset"
        v-model:page-size="localPagination.limit"
        :page-sizes="[5, 10, 20, 50, 100]"
        :small="true"
        layout="total, sizes, prev, pager, next"
        :total="localPagination.total"
        @size-change="paginationChanged"
        @current-change="paginationChanged"
      >
      </el-pagination>
    </template>
  </CardToolbar>
</template>
<script>
import { ref, watch } from "vue";
import CardToolbar from "@/common/components/CardToolbar.vue";
import { paginate, sortArray } from "@/aiBetaBench/helpers";
import { tableCellClassName, tableRowClassName } from "@/utils/staticHelper";

export default {
  name: "ArtistPlaylistTable",
  methods: { tableCellClassName, tableRowClassName },
  components: { CardToolbar },
  props: {
    data: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: "Current Playlists",
    },
  },
  setup(props) {
    const loading = ref(false);
    const tableData = ref([]);
    const tableDataWithoutPagination = ref([]);
    const sortBy = ref(null);
    const sortDir = ref(null);
    const localPagination = ref({
      offset: 1,
      limit: 5,
      total: 0,
      sortBy: "followers",
      sortOrder: "desc",
    });
    const paginationChanged = () => {
      localPagination.value.total = tableDataWithoutPagination.value.length;
      tableData.value = paginate(
        tableDataWithoutPagination.value,
        localPagination.value.limit,
        localPagination.value.offset
      );
      loading.value = false;
    };

    const sortAllDataIfRequired = (array) => {
      if (sortBy.value && sortDir.value) {
        return sortArray(sortBy.value, sortDir.value, array);
      } else {
        return array;
      }
    };

    const sortResults = ({ prop, order }) => {
      sortBy.value = prop;
      sortDir.value = order;
      tableDataWithoutPagination.value = sortAllDataIfRequired(
        JSON.parse(JSON.stringify(tableDataWithoutPagination.value))
      );
      paginationChanged();
    };

    watch(
      () => props.data,
      (value) => {
        if (value.length > 0) {
          loading.value = true;
          tableDataWithoutPagination.value = value;
          paginationChanged();
        }
      },
      { immediate: true }
    );

    return {
      loading,
      tableData,
      sortBy,
      sortDir,
      localPagination,
      sortResults,
      paginationChanged,
    };
  },
};
</script>
