<template>
  <vue-highcharts type="chart" :options="chartData"></vue-highcharts>
</template>
<script>
import VueHighcharts from "vue3-highcharts";
import moment from "moment";
import { computed } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import { formatNumberIntoHumanizeForm } from "@/utils/staticHelper";

export default {
  name: "AreaLineRangeHighChart",
  components: { VueHighcharts },
  props: {
    averages: {
      type: Array,
    },
    ranges: {
      type: Array,
    },
    yLabel: {
      type: String,
    },
  },
  setup(props) {
    const borderColor = getCSSVariableValue("--bs-gray-200");
    const chartData = computed(() => {
      return {
        title: {
          text: "",
        },
        chart: {
          fontFamily: "Helvetica, Arial, sans-serif",
          height: 350,
        },
        tooltip: {
          animation: true,
          backgroundColor: "#ffffff",
          borderColor: "#ffffff",
          shared: true,
          // valueDecimals: 0,
          borderRadius: 12,
          useHTML: true,
          style: {
            fontFamily: "Helvetica, Arial, sans-serif",
            fontSize: "12px",
          },
          formatter: function () {
            return this.points.reduce(function (s, point) {
              return (
                s +
                "<br/>" +
                "<span style='padding-left: 2px; font-size: 20px; color:" +
                point.series.color +
                "'>●</span> " +
                point.series.name +
                ": " +
                "<b style='padding-left: 4px;'>" +
                formatNumberIntoHumanizeForm(point.y) +
                "</b>"
              );
            }, "<b>" + moment(this.x).format("MMM YY") + "</b>");
          },
        },
        xAxis: {
          tickWidth: 0,
          tickLength: 0,
          crosshair: true,
          type: "datetime",
          labels: {
            rotation: -45,
            formatter: (value) => {
              return moment(value.value).format("MMM YY");
            },
            style: {
              fontFamily: "Helvetica, Arial, sans-serif",
              color: "rgb(161, 165, 183)",
              fontSize: "12px",
            },
          },
          lineColor: "transparent",
          plotLines: [
            {
              value: moment().unix() * 1000,
              width: 3,
              color: "#00A1F9",
              dashStyle: "shortdash",
              zIndex: 1,
            },
          ],
        },
        yAxis: [
          {
            visible: true,
            title: {
              // text: props.yLabel,
              text: null,
            },
            labels: {
              formatter: (value) => {
                return formatNumberIntoHumanizeForm(value.value);
              },
              style: {
                fontFamily: "Helvetica, Arial, sans-serif",
                color: "rgb(161, 165, 183)",
                fontSize: "12px",
              },
            },
            gridLineDashStyle: "longdash",
            gridLineColor: borderColor,
          },
        ],
        plotOptions: {
          series: {
            showInLegend: true,
          },
        },
        series: [
          {
            name: "Forecast",
            data: props.averages,
            zIndex: 1,
            lineColor: "#00A1F9",
            color: "#00A1F9",
            fillOpacity: 0.3,
            marker: {
              enabled: false,
              fillColor: "#00A1F9",
              // lineWidth: 1,
              // color: "#00A1F9",
            },
          },
          {
            name: "Trend",
            data: props.ranges,
            type: "arearange",
            lineWidth: 2,
            linkedTo: ":previous",
            color: "rgb(72, 185, 85)",
            lineColor: "rgb(72, 185, 85)",
            fillOpacity: 0.3,
            zIndex: 1,
            marker: {
              enabled: false,
            },
          },
        ],
      };
    });
    return {
      chartData,
    };
  },
};
</script>
<style>
.highcharts-credits {
  display: none !important;
}

.highcharts-xaxis-labels {
  color: rgb(161, 165, 183) !important;
  cursor: default !important;
  font-size: 12px !important;
  font-family: Helvetica, Arial, sans-serif !important;
  fill: rgb(161, 165, 183) !important;
}

.highcharts-yaxis-labels {
  color: rgb(161, 165, 183) !important;
  cursor: default !important;
  font-size: 12px !important;
  font-family: Helvetica, Arial, sans-serif !important;
  fill: rgb(161, 165, 183) !important;
}
</style>
