<template>
  <div class="artist-forecasts margin-bottom-12">
    <EmptyCardLoading
      :loading="loading"
      :type="'skeleton'"
      v-if="loading"
      :card-height="'400px'"
      :rows="9"
    />
    <div
      class="card"
      v-if="averages.length > 0 && ranges.length > 0 && !loading"
    >
      <div class="card-body pb-1">
        <p class="card-title mb-10">Spotify Listeners Forecasts</p>
        <AreaLineRangeHighChart
          :averages="averages"
          :ranges="ranges"
          :yLabel="'Spotify Listeners'"
        />
      </div>
    </div>
  </div>
</template>
<script>
import AreaLineRangeHighChart from "@/artists/components/AreaLineRangeHighChart.vue";
import { onMounted, ref } from "vue";
import { getArtistSpotifyListenersForecastsV2FromMongo } from "@/api/mongoDB";
import EmptyCardLoading from "@/common/components/EmptyCardLoading.vue";
import moment from "moment";
import { useRoute } from "vue-router";

export default {
  name: "ArtistForecastV2",
  components: {
    AreaLineRangeHighChart,
    EmptyCardLoading,
  },
  setup() {
    const loading = ref(false);
    const averages = ref([]);
    const ranges = ref([]);
    const route = useRoute();

    onMounted(async () => {
      try {
        loading.value = true;
        let { data } = await getArtistSpotifyListenersForecastsV2FromMongo(
          route.params.spotifyID
        );
        for (const forecast of data) {
          const average = [];
          const range = [];

          if (forecast.value && forecast.timestp) {
            average.push(
              moment(forecast.timestp).unix() * 1000,
              forecast.value
            );
            averages.value.push(average);
          }

          if (forecast.trend_lower && forecast.trend_upper && forecast.ds) {
            range.push(
              moment(forecast.ds).unix() * 1000,
              forecast.trend_lower,
              forecast.trend_upper
            );
            ranges.value.push(range);
          }
        }
      } catch (e) {
        console.error(e);
      } finally {
        loading.value = false;
      }
    });
    return { loading, averages, ranges };
  },
};
</script>
<style lang="scss" scoped>
.artist-forecasts {
  .card {
    .card-body {
      .card-title {
        font-size: 14px;
        font-weight: 500;
        color: #33647f;
      }
    }
  }
}
</style>
