<template>
  <CardToolbar
    :show-toolbar-buttons="false"
    :description="description"
    :title="title"
    :fontStyle="true"
    :margin="true"
    :padding="false"
    :padding-left="true"
    :shadow-class="true"
  >
    <template v-slot:body>
      <el-row :gutter="16">
        <el-col :span="24">
          <div>
            <apexchart
              type="line"
              height="350"
              :series="series"
              :options="options"
            ></apexchart>
          </div>
        </el-col>
      </el-row>
    </template>
  </CardToolbar>
</template>
<script>
import CardToolbar from "../../common/components/CardToolbar";
import { defineComponent, ref } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import { formatNumberIntoHumanizeForm } from "@/utils/staticHelper";

export default defineComponent({
  name: "LineAndBarChartV2",
  components: { CardToolbar },
  props: {
    description: {
      type: String,
      default: null,
    },
    title: {
      type: String,
    },
    chartSeries: {
      type: Array,
      default: () => [],
    },
    categories: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const fontSize = ref("12px");
    const labelColor = getCSSVariableValue("--bs-gray-500");
    const borderColor = getCSSVariableValue("--bs-gray-200");
    const options = ref({
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "60%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        opacity: 1,
      },
      legend: {
        show: true,
        fontSize: fontSize.value,
        fontWeight: 400,
        labels: {
          colors: labelColor,
          useSeriesColors: false,
        },
      },
      stroke: {
        width: [0, 1],
        dashArray: [0, 8],
      },
      grid: {
        borderColor: borderColor,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      markers: {
        strokeWidth: 3,
      },
      colors: ["rgb(0, 161, 249)", "rgb(0, 179, 0)"],
      labels: props.categories,
      xaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        tickAmount: 30,
        labels: {
          rotate: -45,
          rotateAlways: true,
          hideOverlappingLabels: true,
          showDuplicates: false,
          style: {
            colors: labelColor,
            fontSize: fontSize.value,
            fontWeight: 400,
          },
        },
      },
      yaxis: [
        {
          title: {
            text: props.chartSeries.length > 0 ? props.chartSeries[0].name : "",
            style: {
              color: labelColor,
              fontSize: fontSize,
              fontWeight: 400,
            },
          },
          labels: {
            style: {
              colors: labelColor,
              fontSize: fontSize.value,
              fontWeight: 400,
            },
            formatter: (value) => {
              return formatNumberIntoHumanizeForm(value);
            },
          },
        },
        {
          title: {
            text: props.chartSeries.length > 0 ? props.chartSeries[1].name : "",
            style: {
              color: labelColor,
              fontSize: fontSize,
              fontWeight: 400,
            },
          },
          opposite: true,
          labels: {
            style: {
              colors: labelColor,
              fontSize: fontSize.value,
              fontWeight: 400,
            },
            formatter: (value) => {
              return formatNumberIntoHumanizeForm(value);
            },
          },
        },
      ],
      tooltip: {
        style: {
          fontSize: fontSize.value,
          fontWeight: 400,
        },
        y: {
          formatter: function (value) {
            return formatNumberIntoHumanizeForm(value);
          },
        },
      },
    });
    const series = ref(props.chartSeries);
    return {
      options,
      series,
    };
  },
});
</script>
