<template>
  <div class="mt-2" id="artist_top_songs_catalog">
    <div id="top_10_tracks_on_spotify" class="margin-bottom-12">
      <SpotifyTop10TracksV2
        :tracks="tracks"
        v-if="!public && tracks && tracks.length && !loading"
        :public="public"
      />
    </div>
    <div id="catalogue_age_metrics">
      <el-row :gutter="12">
        <el-col
          :lg="8"
          :md="8"
          :sm="24"
          :xs="24"
          class="margin-bottom-12"
          v-if="!public"
        >
          <kpi-card-v2
            :title="
              artistDetail && artistDetail.averageCatalogAge
                ? formatNumberIntoHumanizeForm(
                    artistDetail.averageCatalogAge / 365,
                    1
                  ) + ' yr'
                : 'N/A'
            "
            :text="'Average Catalogue Age'"
          />
        </el-col>
        <el-col
          :lg="public ? 12 : 8"
          :md="public ? 12 : 8"
          :sm="24"
          :xs="24"
          class="margin-bottom-12"
        >
          <kpi-card-v2
            :title="
              artistDetail && artistDetail.averageCatalogAge
                ? formatNumberIntoHumanizeForm(
                    artistDetail.weightedCatalogAgeFromTotal / 365,
                    1
                  ) + ' yr'
                : 'N/A'
            "
            :text="'Weighted Catalogue Age From Total'"
            :show-hover="!public"
          />
        </el-col>
        <el-col
          :lg="public ? 12 : 8"
          :md="public ? 12 : 8"
          :sm="24"
          :xs="24"
          class="margin-bottom-12"
        >
          <kpi-card-v2
            :title="
              artistDetail && artistDetail.averageCatalogAge
                ? formatNumberIntoHumanizeForm(
                    artistDetail.weightedCatalogAgeFromMonth / 365,
                    1
                  ) + ' yr'
                : 'N/A'
            "
            :text="'Weighted Catalogue Age From Month'"
            :show-hover="!public"
          />
        </el-col>
      </el-row>
      <el-row :gutter="12" v-if="!public">
        <el-col :lg="12" :md="12" :sm="24" :xs="24" class="margin-bottom-12">
          <kpi-card-v2
            :title="
              checkIsValueAvailableOrNot(
                'object',
                artistDetail,
                'percentageOfPlaysOfSongsOlderThan3YearsFromTotal',
                '%',
                1
              )
            "
            :text="'Plays of Songs > 3 Years From Total'"
          />
        </el-col>
        <el-col :lg="12" :md="12" :sm="24" :xs="24" class="margin-bottom-12">
          <kpi-card-v2
            :title="
              checkIsValueAvailableOrNot(
                'object',
                artistDetail,
                'percentageOfPlaysOfSongsOlderThan3YearsFromMonth',
                '%',
                1
              )
            "
            :text="'Plays of Songs > 3 Years From Month'"
          />
        </el-col>
      </el-row>
    </div>
    <TrackCatalogueV2
      v-if="tracks && tracks.length && !loading"
      :tracks="tracks"
      :margin-left="'-56px'"
      :public="public"
    />
  </div>
</template>
<script>
import KpiCardV2 from "@/common/components/KpiCardV2.vue";
import SpotifyTop10TracksV2 from "@/artists/components/SpotifyTop10TracksV2.vue";
import { onMounted, ref } from "vue";
import {
  checkIsValueAvailableOrNot,
  formatNumberIntoHumanizeForm,
  getUniqueValuesFromArray,
} from "@/utils/staticHelper";
import { useRoute } from "vue-router";
import { getTop10TracksFromMongo } from "@/api/mongoTracks";
import TrackCatalogueV2 from "@/common/components/TrackCatalogueV2.vue";

export default {
  name: "ArtistTop10Songs",
  methods: { formatNumberIntoHumanizeForm },
  props: {
    artistDetail: {
      type: Object,
      required: true,
    },
    public: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    TrackCatalogueV2,
    KpiCardV2,
    SpotifyTop10TracksV2,
  },
  setup() {
    const loading = ref(false);
    const tracks = ref([]);
    const route = useRoute();
    onMounted(async () => {
      await getTopTracks();
    });

    const getTopTracks = async () => {
      try {
        loading.value = true;
        let { data } = await getTop10TracksFromMongo(
          route.params.spotifyID,
          15
        );
        tracks.value = data;

        tracks.value = getUniqueValuesFromArray(data, "track");
        data = [];
      } catch (e) {
        console.log(e);
      } finally {
        loading.value = false;
      }
    };

    return {
      loading,
      tracks,
      checkIsValueAvailableOrNot,
    };
  },
};
</script>
<style lang="scss" scoped>
.artist-catalog {
  .card {
    .card-body {
      .card-title {
        font-size: 14px;
        font-weight: 500;
        color: #33647f;
      }
    }
  }
}

.playlist-card-col {
  padding-bottom: calc(var(--bs-gutter-x));
}
</style>
